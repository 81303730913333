import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import Accordion from "~/components/accordion/accordion";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={5}
        lesson={"Lesson 1"}
        color={"ms"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <ContentWrapper>
          <H2 underline>Step 5</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={false}
              setFullScreen={setStudentFullScreen}
            >
              <p>The first category of the rubric includes 8 statements in the "Criteria" column. How do these statements help you judge a website?</p>
              <p>You can think of the criteria on the rubric as a way of keeping track of evidence, in this case about a website's quality. You can then use this evidence to help you make a quick decision about the website and whether it is worth spending the time to read it.</p>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={false}
              setFullScreen={setTeacherFullScreen}
            >
              <p>If your students have used rubrics previously, they may be comfortable with them. If this is new to them, they should understand that the rubric is simply a way to help them keep track of the evidence.</p>

              <Accordion headerText="Note to teachers" className="my-4" open={true}>
                <p className="mt-0">At various points in this module, students will be evaluating different websites. The websites in this lesson (and the green coffee bean extract website in Lesson 2 and the lemonade diet cleanse website in Lesson 3) are fake for purposes of the lessons. This approach was used because (1) a specially designed website ensures the educational purpose for the site is accommodated; (2) websites are often taken down or have their address changed, which would make them inaccessible; and (3) website material is copyrighted. Students do not need to know these sites are fictional. If they ask, you can assure them that the information is a compilation from actual websites.</p>
              </Accordion>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
